<template>
  <div class="noor-payment-container">
    <div class="noor-payment-container-box" v-if="hideCheckout">
      <p class="noor-payment-container-title">{{$t('payment method')}}</p>
      <p class="noor-payment-container-subtitle">{{$t('Please select your payment method')}}</p>
      <div class="noor-payment-container-methods">
        <div class="noor-payment-container-methods-creditCard" @click="showCheckout">
          <p>{{$t('credit card')}}</p>
          <img src="@/assets/icons/credit_card_logo.png" alt />
        </div>
        <!-- <paypal v-if="enablePaypal" :gatewaydetail="paypalDetail" :paymentDetail="purchaseDetail"></paypal> -->
        <div class="noor-payment-container-methods-paypalsub" v-if="enablePaypal">
          <paypalSub :gatewaydetail="paypalSubDetail" :paymentDetail="purchaseDetail"></paypalSub>
        </div>
      </div>
    </div>
    <div class="noor-payment-container-checkout" v-else>
      <cardCheckout
        :checkoutDetail="checkoutDetail"
        :paymentDetail="purchaseDetail"
        :finalPromoAmounts="finalPromoAmounts"
        @closeCheckout="() => hideCheckout = true"
        :paymentPrecheckData="precheckData"
      ></cardCheckout>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    purchaseDetail: {
      type: Object
    },
    checkoutDetail: {
      type: Object
    },
    paypalDetail: {
      type: Object
    },
    paypalSubDetail: {
      type: Object
    },
    finalPromoAmounts: {
      type: Object
    },
    precheckData: {
      type: Object
    }
  },
  data() {
    return {
      hideCheckout: true,
      enablePaypal: true
    };
  },
  watch: {
    finalPromoAmounts(val) {
      if (val.isCoupon) {
        this.enablePaypal = false;
      } else if (!val.isCoupon) {
        this.enablePaypal = true;
      }
    }
  },
  created() {
    console.log("paypal sub details", this.paypalSubDetail);
  },
  methods: {
    showCheckout() {
      this.hideCheckout = false;
    }
  },
  components: {
    paypal: () =>
      import(
        /* webpackChunkName: "paypal" */ "@/components/gateways/paypal.vue"
      ),
    cardCheckout: () =>
      import(
        /* webpackChunkName: "cardCheckout" */ "@/components/gateways/cardCheckout.vue"
      ),
    paypalSub: () => 
      import(/* webpackChunkName: "paypalSub" */ "@/components/gateways/paypalSub.vue")
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./NoorPaymentOptions.scss"
</style>